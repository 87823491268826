<template>
  <div class="px-3 py-3 fc fg-1 w-100 h-100" style="background: #fff">
    <p style="font-size: 20px; font-weight: 600">Analysis <Load :action="ACTIONS.LOAD_ITEMS"/></p>
    <div class="ovf-y-s hide-scroll-bar">
      <CollapsableSection v-for="item of items" :key="item._id"
                          :title="getTitle(item)"
                          style="border-bottom: 1px solid #eee"
                          @titleClick="inspect(item)">
        <table border style="border-collapse: collapse">
          <thead>
            <tr>
              <th>At</th>
              <th>Worker</th>
              <th>Event</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="ev of item.tabEvents" :key="ev._id">
              <td class="px-1">{{ dayjs(ev.t).format('HH:mm:ss DD/MM') }}</td>
              <td class="px-1">{{ev.wStr || ev.w}}</td>
              <td class="px-1">{{ ev.e }}</td>
            </tr>
          </tbody>
        </table>
      </CollapsableSection>
    </div>
    <Spacer/>
    <div class="fr ai-c jc-fe fg-1">
      <PagingToolbar :page="page" :totalItems="totalItems" :items-per-page="20" @update:page="updatePage"/>
    </div>
  </div>
</template>
<script setup>
import {onMounted, ref, watch} from 'vue';
import CollapsableSection from '@/components/UiLib/CollapsableSection';
import Spacer from '@/components/UiLib/Spacer';
import notification from '@/components/UiLib/Api/notification';
import dayjs from 'dayjs';
import api from '@/api';
import PagingToolbar from '@/components/UiLib/PagingToolbar';
import loading from '@/components/UiLib/Api/loading';
import Load from '@/components/UiLib/Load';
import {getWorkerIp} from '@/utils';

const ACTIONS = { LOAD_ITEMS: 'aa:load-items' }

const totalItems = ref(0)
const loadTotalItems = async () => totalItems.value = await api.account.analysisTotal()
const updatePage = v => page.value = v
const items = ref([])

const page = ref(1)
watch(page, () => loadItemsOfPage())
const loadItemsOfPage = async () => {
  try {
    loading.begin(ACTIONS.LOAD_ITEMS)
    items.value = await api.account.analysis(page.value)
    items.value.forEach(item => {
      if (item.tabEvents) {
        item.tabEvents.forEach(ev => getWorkerIp(ev.w).then(rs => ev.wStr = rs))
      }
    })
  } catch (e) {
    notification.err(e)
  } finally {
    loading.end(ACTIONS.LOAD_ITEMS)
  }
}
const getTitle = acc => [
  acc.account.split(':')[0],
  `usedBy: (${acc.usedBy.ip} - ${acc.usedBy.connectStatus})`,
  `tabs: (${acc.runningIns.map(item => `${item.ip} - ${item.connectStatus}`).join(',')})`,
  `lastModified: ${dayjs(acc.lastModified).format('MM-DD HH:mm:ss')}`
].join(' -- ')
const inspect = acc => console.log(acc)
onMounted(() => {
  loadTotalItems()
  loadItemsOfPage()
})
</script>

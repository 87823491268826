<template>
  <section class="script-editor h-100">
    <section data-name="toolbar" class="fr ai-c jc-fe px-2 fg-1"
         style="height: 50px; color: #ddd">
      <template v-if="hasScripts">
        <span>Version: </span>
        <select v-model="selectedScript_id">
          <option v-for="item of scripts" :value="item._id">
            {{item.v}}
          </option>
        </select>
      </template>
      <Spacer/>
      <button @click="newVersion">New version</button>
      <button v-if="selectedScript_id" @click="viewError">Error ({{totalErrors}})</button>
      <button v-if="selectedScript_id" style="width: 100px" @click="saveScript">Save</button>
      <button v-if="selectedScript_id" style="width: 100px" @click="publishScript">Publish</button>
    </section>

    <monaco-editor v-if="hasScripts"
                   :content="code"
                   :dimension="editorSize"
                   ref="codeEditorEl"/>
    <div v-else
         style="height: calc(100% - 100px)"
         class="fr ai-c jc-c">
      No script found
    </div>

    <section v-if="showScriptErrorWindow" class="script-error">
      <div class="script-error--header fr ai-c px-1 py-1 fg-1" style="height: 40px">
        <span>Errors: {{scriptErrors.length}}</span>
        <select v-model="selectedWorker_id">
          <option v-for="item of workers" :value="item._id">
            {{item._id}} ({{item.ip}})
          </option>
        </select>
        <spacer/>
        <icon @click="clearError" class="clickable">fas fa-times:#eee</icon>
        <icon @click="closeScriptErrorWindow" class="clickable">fas fa-angle-down:#eee</icon>
      </div>
      <div style="height: calc(100% - 40px)" class="ovf-y-s hide-scroll-bar">
        <div v-for="scriptError in filteredScriptErrors" :key="scriptError._id"
             class="px-2 py-2"
             style="color: #aaa; border-bottom: thin solid hsla(0,0%,100%,.12);">
          <div class="fr">
            <span style="min-width: 50px">WorkerId: </span>{{scriptError.workerId}}
          </div>
          <div class="fr"><span style="min-width: 50px">Date: </span>{{dayjs(scriptError.createdDate).format('YYYY-MM-DD HH:mm:ss') }}</div>
          <div class="fr"><span style="min-width: 50px">Phase:</span> {{scriptError.phase}}</div>
          <div class="fr"><span style="min-width: 50px">Error:</span> {{scriptError.error}}</div>
          <div class="fr"><span style="min-width: 50px">Stack:</span> {{scriptError.stack}}</div>
        </div>
        <div class="fr ai-c jc-c mt-3">
          <button style="background: #585858; border: none; color: #fff;" @click="loadMoreErrors">More</button>
        </div>
      </div>
    </section>
  </section>
</template>

<script setup>
import dayjs from 'dayjs';
import {ref, onMounted, computed, watch, onBeforeUnmount} from 'vue';
import notification from '@/components/UiLib/Api/notification';
import MonacoEditor from '@/components/MonacoEditor';
import PageHeader from '@/components/PageHeader';
import api from '@/api';
import Spacer from '@/components/UiLib/Spacer';
import msgBox from '@/components/UiLib/Api/msg-box';
import Icon from '@/components/UiLib/Icon';

const codeEditorEl = ref()
const editorSize = computed(() => ({
  width: window.innerWidth < 1000 ? window.innerWidth - 40 : window.innerWidth - 240,
  height: window.innerHeight - 50
}))

const scripts = ref([])
const hasScripts = computed(() => scripts.value && scripts.value.length)
const publishedScriptVersion = ref()
const selectedScript_id = ref()
const selectedScript = ref()
const code = ref()
watch(() => selectedScript_id.value, () => {
  if (selectedScript_id.value) {
    console.log('selectedScript_id', selectedScript_id.value)
    loadScript()
    countErrors()
  }
})

function newVersion() {
  api.script.create().then(rs => {
    const { _id, v, createdDate } = rs
    scripts.value.push({_id, v, createdDate})
    selectedScript_id.value = _id
  }).catch(e => notification.err('Failed to create script with error: ' + e.message))
}
function loadScripts() {
  api.script.readAll().then(rs => scripts.value = rs).catch(e => notification.err('Failed to load script with error: ' + e.message))
}
function loadScript(id) {
  api.script.read(selectedScript_id.value).then(scriptRecord => {
    selectedScript.value = scriptRecord
    code.value = scriptRecord.code
  }).catch(e => notification.err('Failed to load script with error: ' + e.message))
}
async function saveScript() {
  try {
    const content = codeEditorEl.value.getContent()
    if (!content)
      return
    code.value = content
    await api.script.update(selectedScript.value._id, { code: content })
    notification.success('Scripts saved')
  } catch (e) {
    notification.err(e.message)
  }
}
async function publishScript() {
  const answer = await msgBox.show('Confirm', 'Publish script?', msgBox.Buttons.YesNo, msgBox.Icons.Question)
  if (answer === msgBox.Results.no)
    return

  try {
    await saveScript()
    await api.script.publish(selectedScript.value._id)
    publishedScriptVersion.value = selectedScript.value.v
    notification.success('Script published. It might take few minutes for the update in worker machines')
  } catch (e) {
    notification.err(e.message)
  }
}

const selectedWorker_id = ref()
const workers = ref([])
const totalErrors = ref(0)
const showScriptErrorWindow = ref(false)
const scriptErrorPage = ref(0)
const scriptErrors = ref([])
const filteredScriptErrors = computed(() => {
  if (selectedWorker_id.value)
    return scriptErrors.value.filter(err => err.workerId === selectedWorker_id.value)
  return scriptErrors.value
})

function viewError() {
  showScriptErrorWindow.value = true
  api.vps.loadItems(1).then(rs => {
    workers.value = [{_id: 0, ip: 'No filter'}, ...rs]
  })
  loadMoreErrors()
}
function closeScriptErrorWindow() {
  showScriptErrorWindow.value = false
}
function countErrors() {
  api.script.countErrors(selectedScript_id.value).then(rs => totalErrors.value = rs)
}
function loadMoreErrors() {
  scriptErrorPage.value++;
  api.script.readErrors(selectedScript_id.value, scriptErrorPage.value).then(rs => scriptErrors.value.push(...rs))
}
function clearError() {
  api.script.clearErrors(selectedScript.value._id).then(() => {
    scriptErrorPage.value = 0;
    scriptErrors.value = []
  })
}

onMounted(async () => {
  loadScripts()
  publishedScriptVersion.value = await api.script.getLatestVersion()
  window.socket.on('scriptError:create', error => scriptErrors.value.unshift(error))
})
onBeforeUnmount(() => window.socket.off('scriptError:create'))
</script>
<style scoped lang="scss">
.script-error {
  position: fixed;
  bottom: 0;
  height: 50%;
  background-color: #272727;
  width: calc(100% - 240px);
  font-size: 12px;

  &--header {
    color: #aaa;
    background-color: #000;
  }
}
</style>

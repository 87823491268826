<template>
  <div class="px-3 py-3 fc fg-1 w-100 h-50" style="background: #121212; color: #fff">
    <div class="fr ai-c fg-1">
      <span>IP:</span>
      <input :value="ip" @input="ip = $event.target.value"/>
      <button @click="() => findVps(ip)">Find</button>
      <button @click="close">Close</button>
    </div>
    <div>
      <Load :action="ACTIONS.FIND_VPS">
        <table v-if="vps">
          <thead>
          <tr>
            <th>Mã VPS</th>
            <th>Địa chỉ IP</th>
            <th>Kịch bản</th>
            <th>Trạng thái</th>
            <th>Số tab R/A/C</th>
            <th>Thông tin</th>
            <th>Full Disk?</th>
            <th>Ngày tạo</th>
            <th>Hành động</th>
          </tr>
          </thead>
          <tbody>
            <VPSItem :item="vps"/>
          </tbody>
        </table>
      </Load>
    </div>
  </div>
</template>
<script setup>
import VPSItem from '@/components/VPSItem';
import Load from '@/components/UiLib/Load';
import {ref} from 'vue';
import loading from '@/components/UiLib/Api/loading';
import api from '@/api';
import notification from '@/components/UiLib/Api/notification';

const emit = defineEmits(['close'])
const ACTIONS = { FIND_VPS: 'vps:find' }
const ip = ref()
const vps = ref()
const findVps = ip => {
  loading.begin(ACTIONS.FIND_VPS)
  api.vps.find(ip).then(rs => vps.value = rs).catch(e => notification.err(e)).finally(() => loading.end(ACTIONS.FIND_VPS))
}
const close = () => emit('close')
</script>

<template>
  <section class="dashboard fc w-100 h-100 fg-3 py-3 px-3 ovf-y-s hide-scroll-bar">
    <section data-name="metric" class="fr ai-c jc-sb fg-2">
      <div class="f1 metric-block bc-i-0">
        <p class="label">VPS</p>
        <Load :action="ACTIONS.GET_APP_METRIC">
          <p class="val">{{ appMetric.vpss && appMetric.vpss.total }}</p>
        </Load>
      </div>
      <div class="f1 metric-block bc-r-0">
        <p class="label">Account</p>
        <Load :action="ACTIONS.GET_APP_METRIC">
          <p class="val">{{ appMetric.accounts && appMetric.accounts.total }}</p>
        </Load>
      </div>
      <div class="f1 metric-block bc-pp-0">
        <p class="label">Proxy</p>
        <Load :action="ACTIONS.GET_APP_METRIC">
          <p class="val">{{ appMetric.proxies && appMetric.proxies.total }}</p>
        </Load>
      </div>
      <div class="f1 metric-block bc-dpp-0">
        <p class="label">Video</p>
        <Load :action="ACTIONS.GET_APP_METRIC">
          <p class="val">{{ appMetric.videos && appMetric.videos.total }}</p>
        </Load>
      </div>
      <div class="f1 metric-block bc-p-0">
        <p class="label">Keyword</p>
        <Load :action="ACTIONS.GET_APP_METRIC">
          <p class="val">{{ appMetric.keywords && appMetric.keywords.total }}</p>
        </Load>
      </div>
    </section>

    <section class="metric-block big-block px-2 py-2" style="border-radius: 6px">
      <p class="label mb-2">
        Thống kê
        <span class="mr-1">(cập nhật lần cuối vào lúc {{ dayjs(lastUpdate.value).format('HH:mm:ss') }})</span>
        <button @click="loadResourceStatus"
          style="background: #1c2026; border: none; color: #9d9b9b; box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.5)">Cập
          nhật</button>
      </p>
      <p><span style="display: inline-block; width: 80px">VPS:</span>
        <Load :action="ACTIONS.GET_VPS_STATUS">{{ vpsMetric }}</Load> - tabs:
        <Load :action="ACTIONS.GET_TOTAL_RUNNING_TABS">
          <tooltip content="Đang chạy">{{ totalRunningTabs }}</tooltip>
        </Load>
        /
        <Load :action="ACTIONS.GET_TOTAL_ALLOC_TABS">
          <tooltip content="Đã tạo sẵn">{{ totalAllocTabs }}</tooltip>
        </Load>
        /
        <Load :action="ACTIONS.GET_TOTAL_CFG_TABS">
          <tooltip content="Cấu hình sẵn">{{ totalConfigTabs }}</tooltip>
        </Load>
      </p>
      <p><span style="display: inline-block; width: 80px">Account:</span>
        <Load :action="ACTIONS.GET_ACC_STATUS">{{ accMetric }}</Load>
      </p>
      <p><span style="display: inline-block; width: 80px">Premium:</span>
        <Load :action="ACTIONS.GET_PREMIUM_STATUS">{{ premiumStatus }}</Load>
      </p>
      <p><span style="display: inline-block; width: 80px">Proxy:</span>
        <Load :action="ACTIONS.GET_PROXY_STT">{{ proxyMetric }}</Load>
      </p>
      <p><span style="display: inline-block; width: 80px">Video:</span>
        <Load :action="ACTIONS.GET_VIDEO_STT">{{ videoMetric }}</Load>
      </p>
    </section>

    <section class="metric-block big-block px-2 py-2 fc fg-2" style="border-radius: 6px">
      <p class="label">Cài trên máy mới</p>
      <div class="fr fg-5">
        <div class="f1 fc fg-1">
          <p>Bấm biểu tượng download bên dưới để tải file Powershell script xuống:</p>
          <div class="px-2 py-2 code-block clickable fr ai-c" style="border-radius: 6px; background: #111217"
            @click="download(psDownloadFilePath)">
            <icon class="mr-1">fas fa-download@20:#fff</icon>
            <div style="line-break: anywhere; font-size: 14px; ">Download now</div>
          </div>
          <p>Sau đó chạy CMD với quyền admin, cd đến thư mục lưu file bên trên, chạy câu lệnh sau:</p>
          <div class="px-2 py-2 code-block clickable fr" style="border-radius: 6px; background: #111217">
            <icon class="mr-1" @click="copyToClipboard(psExecuteCmd)">fas fa-copy@20:#fff</icon>
            <div style="line-break: anywhere; font-size: 14px; ">{{ psExecuteCmd }}</div>
          </div>
        </div>

        <iframe width="280" height="157" src="https://www.youtube.com/embed/rDYb-fv0dyg" title="YouTube video player"
          frameborder="0" style="border-radius: 10px"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
    </section>

    <section class="metric-block px-2 py-2 fc fg-2" style="border-radius: 6px">
      <p class="label">Cập nhật cho máy đã cài</p>
      <div class="fr fg-5">
        <div class="f1">
          <p class="mb-1">Tải package này xuống:</p>
          <div class="px-2 py-2 code-block clickable fr ai-c" style="border-radius: 6px; background: #111217">
            <icon class="mr-1" @click="copyToClipboard(packageDownloadPath)">fas fa-copy@20:#fff</icon>
            <div style="line-break: anywhere; font-size: 14px; ">{{ packageDownloadPath }}</div>
          </div>
        </div>
        <div>
          <iframe width="280" height="157" src="https://www.youtube.com/embed/dFrcivcXncA" title="YouTube video player"
            frameborder="0" style="border-radius: 10px"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </div>
      </div>
    </section>
  </section>
</template>
<script setup>
import { onBeforeUnmount, onMounted, ref, computed } from 'vue'
import dayjs from 'dayjs';
import sysAdminApi from '@/api/sysadmin'
import { copyToClipboard, metric2Str } from '@/utils';
import api from '@/api'
import loading from '@/components/UiLib/Api/loading';
import Load from '@/components/UiLib/Load';
import Tooltip from '@/components/UiLib/Tooltip';
import Icon from '@/components/UiLib/Icon';
import notification from '@/components/UiLib/Api/notification';
import { BE_URL } from '@/constants';

const ACTIONS = {
  GET_APP_METRIC: 'db:am:load',
  GET_VPS_STATUS: 'db:vps:metric',
  GET_TOTAL_RUNNING_TABS: 'db:vps:total-running',
  GET_TOTAL_ALLOC_TABS: 'db:vps:total-alloc',
  GET_TOTAL_CFG_TABS: 'db:vps:total-cfg',
  GET_ACC_STATUS: 'db:acc:metric',
  GET_PREMIUM_STATUS: 'db:acc:premium-status',
  GET_PROXY_STT: 'db:proxy:metric',
  GET_VIDEO_STT: 'db:video:metric',
}

const appMetric = ref({
  accounts: 0,
  keywords: 0,
  proxies: 0,
  videos: 0,
  vps: 0
})
const loadAppMetric = () => {
  loading.begin(ACTIONS.GET_APP_METRIC)
  sysAdminApi.getAppMetric()
    .then(resp => appMetric.value = resp)
    .finally(() => loading.end(ACTIONS.GET_APP_METRIC))
}

// metrics
const lastUpdate = ref(new Date())
const vpsMetric = ref('')
const getVPSStatus = () => {
  loading.begin(ACTIONS.GET_VPS_STATUS)
  api.vps.metric().then(data => vpsMetric.value = metric2Str(data)).finally(() => loading.end(ACTIONS.GET_VPS_STATUS))
}
const totalRunningTabs = ref(0)
const getTotalRunningTabs = () => {
  loading.begin(ACTIONS.GET_TOTAL_RUNNING_TABS)
  api.vps.totalRunningTabs().then(data => {
    if (data && data.length)
      totalRunningTabs.value = data[0].sum
  }).finally(() => loading.end(ACTIONS.GET_TOTAL_RUNNING_TABS))
}
const totalAllocTabs = ref(0)
const getTotalAllocTabs = () => {
  loading.begin(ACTIONS.GET_TOTAL_ALLOC_TABS)
  api.vps.totalAllocTabs().then(data => {
    if (data && data.length)
      totalAllocTabs.value = data[0].sum
  }).finally(() => loading.end(ACTIONS.GET_TOTAL_ALLOC_TABS))
}
const totalConfigTabs = ref(0)
const getTotalConfigTabs = () => {
  loading.begin(ACTIONS.GET_TOTAL_CFG_TABS)
  api.vps.totalConfigTabs().then(data => {
    if (data && data.length)
      totalConfigTabs.value = data[0].sum
  }).finally(() => loading.end(ACTIONS.GET_TOTAL_CFG_TABS))
}

const accMetric = ref('')
const getAccountStatus = () => {
  loading.begin(ACTIONS.GET_ACC_STATUS)
  api.account.metric().then(data => accMetric.value = metric2Str(data)).finally(() => loading.end(ACTIONS.GET_ACC_STATUS))
}

const premiumStatus = ref('')
const getPremiumStatus = () => {
  loading.begin(ACTIONS.GET_PREMIUM_STATUS)
  api.account.premiumStatus().then(data => premiumStatus.value = metric2Str(data)).finally(() => loading.end(ACTIONS.GET_PREMIUM_STATUS))
}

const proxyMetric = ref('')
const getProxyStatus = () => {
  loading.begin(ACTIONS.GET_PROXY_STT)
  api.proxy.metric().then(data => proxyMetric.value = metric2Str(data)).finally(() => loading.end(ACTIONS.GET_PROXY_STT))
}

const videoMetric = ref('')
const getVideoStatus = () => {
  loading.begin(ACTIONS.GET_VIDEO_STT)
  api.video.metric().then(data => videoMetric.value = metric2Str(data)).finally(() => loading.end(ACTIONS.GET_VIDEO_STT))
}

const loadResourceStatus = () => {
  getVPSStatus()
  getTotalRunningTabs()
  getTotalAllocTabs()
  getTotalConfigTabs()
  getAccountStatus()
  getPremiumStatus()
  getProxyStatus()
  getVideoStatus()
  lastUpdate.value = new Date()
}

///
const ytbotInstallerFileName = 'ytbot-install.ps1'
const psDownloadFilePath = ref('')

const amqp = ref('')
const access_token = localStorage.getItem('access_token')
const packageDownloadPath = ref('')
const pptr = ref('')
const psExecuteCmd = computed(() => `powershell -executionpolicy Bypass -File ".\\${ytbotInstallerFileName}"`)

let resourceStatusInterval

const download = url => {
  sysAdminApi.getFile(url)
    .then(txt => {
      txt = txt.replace('__CONTROLLER_HOST__', BE_URL)
      txt = txt.replace('__AMQP__', amqp.value)
      txt = txt.replace('__ACCESS_TOKEN__', access_token)
      txt = txt.replace('__PACKAGE__', packageDownloadPath.value)
      txt = txt.replace('__PPTR__', pptr.value)
      txt = txt.replace('__USER_ID__', window.user._id)
      txt = txt.replace('__GOLOGIN_TOKEN__', window.user.gologinToken)
      const blob = new Blob([txt], { type: 'text/plain' })
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = ytbotInstallerFileName; // the filename you want
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      notification.success(`${ytbotInstallerFileName} downloaded`)
    })
    .catch(() => alert('oh no!'));
}

onMounted(() => {
  api.systemConfig.get('ps-install-script-download-path').then(res => psDownloadFilePath.value = res)
  api.systemConfig.get('package-download-path').then(rs => packageDownloadPath.value = rs)
  api.systemConfig.get('amqp').then(rs => amqp.value = rs)
  api.systemConfig.get('pptr-download-path').then(rs => pptr.value = rs)

  loadAppMetric()
  loadResourceStatus()
  resourceStatusInterval = setInterval(loadResourceStatus, 60000)
})
onBeforeUnmount(() => {
  clearInterval(resourceStatusInterval)
})
</script>
<style scoped lang="scss">
.dashboard {
  background: linear-gradient(90deg, #303741, #06070c);
}

.metric-block {
  background: linear-gradient(90deg, #272b34, #1a1a22);
  box-shadow: 2px 2px 4px 0px rgb(0 0 0 / 40%);

  border-radius: 10px;
  padding: 20px;
  color: #676b79;

  .label {
    font-size: 12px;
    color: #adaeaf;
  }

  .val {
    font-size: 30px;
  }
}

.big-block {
  background: linear-gradient(45deg, #272b34, #1a1a22);
}

.code-block {
  background: #434750;
}</style>

<template>
  <div class="h-100 w-100">
    <page-header>
      <span class="mr-2">{{videoMetricStr}}</span>
    </page-header>
    <div class="fr" style="height: calc(100% - 50px)">
      <tableu :api="api.video" :parse-item="parseItem" edge="video">
        <template #thead>
          <tr>
            <th>Video Id</th>
            <th>Tiêu đề</th>
            <th>Thời lượng</th>
            <th>Trạng thái</th>
            <th>Hit</th>
            <th>Complete</th>
            <th>Lượt xem</th>
            <td>By url</td>
            <td>By click</td>
            <th>Giờ xem</th>
            <th>Sửa lần cuối</th>
            <th>Ngày thêm vào</th>
            <th>Hành động</th>
          </tr>
        </template>
        <template #body="{items, deleteItem}">
          <tr v-for="item of items" :key="item._id">
            <td><a :href="item.url" target="_blank" class="c-gre-2">{{getVideoId(item.url)}}</a></td>
            <td><p style="max-width: 300px; line-break: initial">{{shortenVideoTitle(item.title)}}</p></td>
            <td>{{item.duration}}</td>
            <td>{{item.status}}</td>
            <td>{{item.hit}}</td>
            <td>{{item.complete || "0"}}</td>
            <td>{{item.views}}</td>
            <td>{{item.viewViaUrl}}</td>
            <td>{{item.viewViaClick}}</td>
            <td>{{readableMs(item.watchHours)}}</td>
            <td>{{dayjs(item.lastModified).format('HH:mm DD/MM')}}</td>
            <td>{{dayjs(item.createdDate).format('DD/MM/YY')}}</td>
            <td>
              <div class="fr ai-c fg-1">
                <tooltip content="Xem thống kê">
                  <button class="fn-btn" @click="analytics(item)">
                    <icon>fas fa-chart-bar@16</icon>
                  </button>
                </tooltip>
                <tooltip content="Xoá">
                  <button class="fn-btn" @click="deleteItem([item._id])">
                    <icon>fas fa-times@16:#d50404</icon>
                  </button>
                </tooltip>
              </div>
            </td>
          </tr>
        </template>
        <template #footer>
          <button @click="removeByFile">Xoá theo file</button>
          <button @click="removeBrokenVideos">Xoá video lỗi</button>
          <button @click="setDuration">Sửa thời lượng video</button>
        </template>
      </tableu>
    </div>
  </div>
</template>
<script setup>
import PageHeader from '@/components/PageHeader';
import api from '@/api'
import Tableu from '@/components/UiLib/Tableu';
import dayjs from 'dayjs';
import Icon from '@/components/UiLib/Icon';
import {metric2Str, notEmpty, openFileDialog, readableMs, readFile, removeCarry} from '@/utils';
import msgBox from '@/components/UiLib/Api/msg-box';
import notification from '@/components/UiLib/Api/notification';
import * as XLSX from 'xlsx';
import _ from 'lodash';
import {ref, computed, onMounted, onBeforeUnmount} from 'vue';
import Tooltip from '@/components/UiLib/Tooltip';
import dialog from '@/components/UiLib/Api/dialog';

function parseItem(line) {
  const [url, duration, title] = line.split(',')
  return {
    url,
    duration: duration || '-',
    title,
    views: 0,
    watchHours: 0,
    status: 'ACTIVE',
    createdDate: new Date()
  }
}

function analytics(item) {
  window.open(`/analytics/${item._id}`)
}

function getVideoId(url) {
  const parts = url.split('=')
  if (parts.length >= 2)
    return parts[1]
  return url
}

function shortenVideoTitle(title) {
  return title && title.substr(0, 40) + '...'
}

async function setDuration() {
  const duration = await dialog.show((_, {emit}) => {
    const duration = ref('00:00:00')
    const updateDuration = (e) => duration.value = e.target.value
    const isDurationValid = computed(() => /^\d{2}:\d{2}:\d{2}$/g.test(duration.value))
    const close = () => emit('close')
    const proceed = () => emit('close', duration.value)
    return () => <div class="px-3 py-3 fc fg-1" style="background: #fff; margin: 0 auto; border-radius: 6px">
      <p style="font-size: 20px; font-weight: 600">Sửa thời lượng video</p>
      <div style="max-height: 400px" class="ovf-y-s hide-scroll-bar fc fg-1">
        <p>Thời lượng (giờ:phút:giây)</p>
        <input class="w-100" value={duration.value} onInput={updateDuration}/>
        <p style="font-size: 12px; max-width: 300px">
          Lưu ý:
          <ul>
            <li>- Chỉ chấp nhận định dạng giờ:phút:giây, nếu thời lượng dài hơn 1 ngày thì tăng số giờ</li>
            <li>- Số giờ tối đa là 99 giờ</li>
          </ul>
        </p>
      </div>

      <div class="fr ai-c jc-fe fg-1">
        <button onClick={close}>Huỷ</button>
        <button onClick={proceed} class={[isDurationValid.value ? '' : 'disable']}>Đồng ý</button>
      </div>
    </div>
  })

  if (duration) {
    await api.video.setDuration(duration)
    notification.success('Đã cập nhật thời lượng')
    location.reload()
  }
}

async function removeByFile() {
  const files = await openFileDialog({multiple: false})
  for (const file of files) {
    try {
      const content = await readFile(file)
      const urls = content.split('\n').filter(notEmpty).map(removeCarry).map(url => url.split(',')[0])
      await api.video.deleteByUrls(urls)
      notification.success('Đã xoá video')
    } catch (e) {
      notification.err(`Lỗi: ${e.message}`)
    }
  }
}

async function removeBrokenVideos() {
  try {
    const rs = await msgBox.show('Xác nhận', 'Xoá video lỗi?', msgBox.Buttons.YesNo, msgBox.Icons.Question)
    if (rs === msgBox.Results.no) return
    const data = await api.video.deleteBroken()
    await exportVideos(data)
    location.reload()
  } catch (e) {
    notification.err(e.message)
  }
}

async function exportVideos(videos) {
  const wb = XLSX.utils.book_new()
  const data = [
    [`Tong:${videos.length}`],
    [''],
    ['Title', 'Url', 'Thoi luong', 'Luot xem', 'Thoi luong xem', 'Ngay them vao'],
    ..._.map(videos, vid => [vid.title, vid.url, vid.duration, vid.views, vid.watchHours, dayjs(vid.createdDate).format('YYYY-MM-DD')])
  ]
  XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(data), dayjs().format('YYYY-MM-DD'));
  XLSX.writeFile(wb, 'broken_videos.xlsx')
}

let loadVideoMetricInterval
const videoMetric = ref({})
const videoMetricStr = computed(() => metric2Str(videoMetric.value))
const getVideoMetric = () => api.video.metric().then(data => videoMetric.value = data)

onMounted(() => {
  getVideoMetric()
  loadVideoMetricInterval = setInterval(getVideoMetric, 10000)
})
onBeforeUnmount(() => clearInterval(loadVideoMetricInterval))
</script>

<template>
  <tr>
    <td @click="inspectWorker()">{{ item._id }}</td>
    <td>{{ item.ip }}</td>
    <td>{{ dayjs(new Date(+item.scriptVersion)).format('MM-DD HH:mm') }}</td>
    <td>
      <div class="fr ai-c fg-1">
        <span :style="{ color: item.connectStatus === 'OFFLINE' ? 'red' : 'green' }">
          {{ item.connectStatus }} ({{ readableDiff(item.lastModified) }})
        </span>
      </div>
    </td>
    <td>
      <div class="fr ai-c fg-1">
        <tooltip content="Xem trạng thái tabs">
          <button class="fn-btn" @click="showRunningTabs(item)">
            <icon>fas fa-search@16</icon>
          </button>
        </tooltip>
        <span :style="{ color: item.runningTabs > item.configTabs ? 'red' : 'green' }">
          {{ item.runningTabs }} / {{ item.allocatedTabs }} / {{ item.configTabs || '?' }}
        </span>
      </div>
    </td>
    <td>CPU: {{ item.cpuUsage }}% -- RAM: {{ item.memUsage }}% ({{ Math.floor((item.totalMemMb || 0) / 1024) }} GB)</td>
    <td>{{ dayjs(item.createdDate).format('DD/MM/YY') }}</td>
    <td>
      <div class="fr ai-c fg-1">
        <tooltip v-if="item.fullDisk" content="Xóa trạng thái full ổ đĩa">
          <button class="fn-btn" @click="clearFullDisk(item)">
            <i class="material-icons" style="font-size: 16px">refresh</i>
          </button>
        </tooltip>
      </div>
    </td>
    <td>
      <div class="fr ai-c fg-1">
        <tooltip content="Cập nhật phần mềm">
          <button class="fn-btn" @click="update(item)">U</button>
        </tooltip>
        <tooltip content="Thu hồi tài nguyên">
          <button class="fn-btn" @click="release(item)">R</button>
        </tooltip>
        <tooltip content="Chạy">
          <button class="fn-btn" @click="start(item)"><icon>fas fa-play@16:#43a047</icon></button>
        </tooltip>
        <tooltip content="Dừng">
          <button class="fn-btn" @click="stop(item)"><icon>fas fa-pause@16:#d50404</icon></button>
        </tooltip>
        <tooltip content="Xoá">
          <button class="fn-btn" @click="deleteItem(item)"><icon>fas fa-times@16:#d50404</icon></button>
        </tooltip>
      </div>
    </td>
  </tr>
</template>
<script setup>
import Icon from '@/components/UiLib/Icon';
import Tooltip from '@/components/UiLib/Tooltip';
import dayjs from 'dayjs';
import {readableDiff} from '@/utils';
import dialog from '@/components/UiLib/Api/dialog';
import CollapsableSection from '@/components/UiLib/CollapsableSection';
import MSwitch from '@/components/UiLib/Switch';
import Spacer from '@/components/UiLib/Spacer';
import {onBeforeUnmount, onMounted, ref} from 'vue';
import api from '@/api';
import notification from '@/components/UiLib/Api/notification';
import msgBox from '@/components/UiLib/Api/msg-box';
import DCVPSRunningTabs from '@/components/DCVPSRunningTabs';

defineProps({item: Object})

const inspectWorker = () => console.log(props.item)
const clearFullDisk = item => api.vps.clearFullDisk(item._id)
function update(item) {
  msgBox.show('Xác nhận', 'Cập nhật worker?').then(rs => {
    if (rs === msgBox.Results.yes) {
      api.systemConfig.getIndexWorkerFilePath().then(url => window.rabbitmq.toWatcher('update', { workerId: item._id, url }))
    }
  })
}

function release(item) {
  msgBox.show('Xác nhận', 'Giải phóng account từ phía server?').then(rs => {
    if (rs === msgBox.Results.yes) {
      api.vps.release(item._id)
      window.rabbitmq.toWorker('release', {workerId: item._id})
    }
  })
}

function start(item) {
  msgBox.show('Xác nhận', `Chạy worker?`).then(rs => {
    if (rs === msgBox.Results.yes) {
      api.vps.release(item._id)
      window.rabbitmq.toWatcher('start', {workerId: item._id})
    }
  })
}

function stop(item) {
  msgBox.show('Xác nhận', `Tắt worker?`).then(rs => {
    if (rs === msgBox.Results.yes) {
      api.vps.release(item._id)
      window.rabbitmq.toWorker('stop', {workerId: item._id}) // compatibility -- remote later
      window.rabbitmq.toWatcher('stop', {workerId: item._id})
    }
  })
}

function showRunningTabs(item) {
  dialog.show({ component: DCVPSRunningTabs, data: { item } })
}

function deleteItem(item) {
  msgBox.show('Xác nhận', 'Xoá Worker?').then(rs => {
    if (rs === msgBox.Results.no) {
      return
    }
    window.rabbitmq.toWorker('stop', {workerId: item._id})
    (api.vps.delete(item._id)
    .then(() => notification.success('Đã xoá'))
    .catch(e => notification.err(e.message)))
  })
}
</script>
<style scoped lang="scss">

td {
  height: 30px;
  padding: 0 16px;
  color: #ddd;
  border-bottom: thin solid hsla(0, 0%, 100%, .12);
}
</style>

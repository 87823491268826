<template>
  <div class="w-100 h-100 px-3 py-3">
    <div v-for="annInDate in announcements" :key="annInDate.k" class="mb-1">
      <p class="c-b-3 mb-1" style="font-size: 24px">{{annInDate.k}}</p>
      <ol class="pl-3 c-b-0">
        <li v-for="ann in annInDate.v" :key="ann">
          {{ann.content}}
        </li>
      </ol>
    </div>
  </div>
</template>
<script setup>
import {ref, onMounted} from 'vue'
import _ from 'lodash'
import dayjs from 'dayjs';
import hmm from '@/api/hmm'
import notification from '@/components/UiLib/Api/notification';

const announcements = ref([])

onMounted(() => {
  hmm.announcement.find({}).sort({at: -1}).$.then(rs => {
    console.log('rs', rs)
    const groupByDates = _.groupBy(rs, item => dayjs(item.at).format('DD/MM/YYYY'))
    console.log('groupByDates', groupByDates)
    announcements.value = Object.keys(groupByDates).map(k => ({ k, v: groupByDates[k] }))
    console.log('announcements.value', announcements.value)
  }).catch(notification.err)
})
</script>

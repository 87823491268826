<template>
  <div class="h-100 w-100">
    <page-header>
      <span>{{proxyMetricStr}}</span>
    </page-header>
    <div class="fr" style="height: calc(100% - 50px)">
      <tableu ref="tableuRef" :api="api.proxy" :parse-item="parseItem" :enrich-item="enrichItem" edge="proxy">
        <template #thead>
          <tr>
            <th>Proxy</th>
            <th>Credit</th>
            <th>Trạng thái</th>
            <th>Ngày tạo</th>
            <th>Hành động</th>
          </tr>
        </template>
        <template #body="{items, deleteItem}">
          <tr v-for="item of items" :key="item._id">
            <td>{{item.proxy}}</td>
            <td>{{item.credit}}</td>
            <td>
              <div class="fr ai-c fg-1" style="text-transform: lowercase">{{item.status}}</div>
            </td>
            <td>{{dayjs(item.createdDate).format('DD/MM/YY')}}</td>
            <td>
              <div class="fr ai-c fg-1">
                <tooltip content="Làm mới">
                  <button class="fn-btn" @click="api.proxy.release(item)">
                    R
                  </button>
                </tooltip>
                <tooltip content="Xoá">
                  <button class="fn-btn" @click="deleteItem([item._id])">
                    <icon>fas fa-times@16:#d50404</icon>
                  </button>
                </tooltip>
              </div>
            </td>
          </tr>
        </template>
        <template #footer>
          <button @click="showProxyUsage">Hiện trạng thái</button>
          <button @click="importAndGenProxy">Nhập & trộn dải IP</button>
          <button @click="removeAllProxy">Xoá proxy</button>
        </template>
      </tableu>
    </div>
  </div>
</template>
<script setup>
import PageHeader from '@/components/PageHeader';
import api from '@/api'
import Tableu from '@/components/UiLib/Tableu';
import dayjs from 'dayjs';
import Icon from '@/components/UiLib/Icon';
import notification from '@/components/UiLib/Api/notification';
import {computed, onBeforeUnmount, onMounted, ref} from 'vue';
import {metric2Str, notEmpty, readFile, removeCarry} from '@/utils';
import {openFileDialog} from '@/utils';
import dialog from '@/components/UiLib/Api/dialog';
import _ from 'lodash'
import Tooltip from '@/components/UiLib/Tooltip';
import msgBox from '@/components/UiLib/Api/msg-box';

const tableuRef = ref()

function parseItem(txtLine) {
  return {
    proxy: txtLine,
    ip: txtLine.split(':')[0],
    status: 'NEW',
    credit: 100,
    createdDate: new Date()
  }
}
function enrichItem(item) {
}
async function showProxyUsage() {
  const ipUsages = await api.proxy.usage()
  const ipBrokens = await api.proxy.getBrokenProxies()
  await dialog.show((_, {emit}) => {
    const proxies = ref(ipBrokens || {})
    const removeBrokenProxy = async (proxyIp) => {
      const resp = await api.proxy.deleteBrokenByIp(proxyIp)
      if (resp.deletedCount) {
        notification.success(`Đã xoá ${resp.deletedCount} proxy`)
        delete proxies.value[proxyIp]
      }
    }
    const removeProxyIP = async (proxyIp) => {
      const resp = await api.proxy.deleteByIp(proxyIp)
      if (resp.deletedCount) {
        notification.success(`Đã xoá ${resp.deletedCount} proxy`)
        delete proxies.value[proxyIp]
      }
    }
    const close = () => emit('close')

    return () => <div class="px-3 py-3 fc fg-1" style="background: #fff; margin: 0 auto; border-radius: 6px">
      <p style="font-size: 20px; font-weight: 600">Trạng thái sử dụng</p>
      <div style="max-height: 400px" class="ovf-y-s hide-scroll-bar">
        <table border style="border-collapse: collapse">
          <thead>
          <tr>
            <th class="px-1 py-1">STT</th>
            <th class="px-1 py-1">IP</th>
            <th class="px-1 py-1">Đã dùng</th>
            <th class="px-1 py-1">Lỗi</th>
            <th class="px-1 py-1">Tổng</th>
            <th class="px-1 py-1">Hành động</th>
          </tr>
          </thead>
          <tbody>
          {ipUsages.map((ip, i) => <tr>
            <td>
              <div class="px-1 py-1">{i + 1}</div>
            </td>
            <td>
              <div class="px-1 py-1">{ip.ip}</div>
            </td>
            <td>
              <div class="px-1 py-1">{ip.used}</div>
            </td>
            <td>
              <div class="px-1 py-1">
                {proxies.value[ip.ip] ? proxies.value[ip.ip].broken : 0}
              </div>
            </td>
            <td>
              <div class="px-1 py-1">
                {ip.total}
              </div>
            </td>
            <td>
              {proxies.value[ip.ip] && <div class="fr ai-c fg-1 px-1 py-1">
                <button onClick={() => removeBrokenProxy(ip.ip)}>Chỉ xoá port lỗi</button>
                <button onClick={() => removeProxyIP(ip.ip)}>Xoá toàn bộ</button>
              </div>
              }
            </td>
          </tr>)}
          </tbody>
        </table>
      </div>

      <div class="fr ai-c jc-fe">
        <button onClick={close}>Đóng</button>
      </div>
    </div>
  })
}

let loadProxyMetric
const proxyMetric = ref({})
const proxyMetricStr = computed(() => metric2Str(proxyMetric.value))
const getProxyMetric = () => api.proxy.metric().then(data => proxyMetric.value = data)

async function importAndGenProxy() {
  const files = await openFileDialog({multiple: false})
  if (files.length) await importFiles(files)
}
async function importFiles(files) {
  for (const file of files) {
    try {
      const fileContent = await readFile(file)
      const proxies = fileContent.split('\n').filter(notEmpty).map(removeCarry)
      const range = genRange(proxies)
      const importChunks = _.chunk(range, 700)
      await Promise.all(importChunks.map(chunk => api.proxy.create(chunk)))
      console.log( `${file.name} imported!`)
      notification.success( `${file.name} imported!`, 3000)
    } catch (e) {
      console.error(e)
      notification.err(e.message, 3000)
    }
  }
}
function genRange(proxies) {
  const output = []
  for (let i=0; i<350; ++i) {
    for (let pro of proxies) {
      const [ip, u, p] = pro.split(':')
      output.push(parseItem(`${ip}:${30000 + i}:${u}:${p}`))
    }
  }
  return output
}
async function removeAllProxy() {
  try {
    const rs = await msgBox.show('Confirm', 'Delete all proxy?')
    if (rs === msgBox.Results.no)
      return
    await hmm.proxy.deleteMany({owner: window.user._id}).$
    notification.success('Proxy deleted')
    tableuRef.value.loadItems()
  } catch (e) {
    notification.err(e)
  }
}
onMounted(() => {
  getProxyMetric()
  loadProxyMetric = setInterval(getProxyMetric, 10000)
})
onBeforeUnmount(() => clearInterval(loadProxyMetric))
</script>

<template>
  <div class="h-100 w-100">
    <page-header/>
    <div class="fr" style="height: calc(100% - 50px)">
      <tableu :api="api.keyword" :parse-item="parseItem" edge="keyword">
        <template #thead>
          <tr>
            <th>Từ khoá</th>
            <th>Ngày thêm vào</th>
            <th>Hành động</th>
          </tr>
        </template>
        <template #body="{items, deleteItem}">
          <tr v-for="item of items" :key="item._id">
            <td>{{item.keyword}}</td>
            <td>{{dayjs(item.createdDate).format('DD/MM/YYYY') }}</td>
            <td>
              <tooltip content="Xoá">
                <button style="width: 20px; height: 20px; padding: 0;" @click="deleteItem([item._id])">
                  <icon>fas fa-times@16:#d50404</icon>
                </button>
              </tooltip>
            </td>
          </tr>
        </template>
      </tableu>
    </div>
  </div>
</template>
<script setup>
import PageHeader from '@/components/PageHeader';
import api from '@/api'
import Tableu from '@/components/UiLib/Tableu';
import dayjs from 'dayjs';
import Icon from '@/components/UiLib/Icon';
import Tooltip from '@/components/UiLib/Tooltip';

function parseItem(txtLine) {
  return {
    keyword: txtLine,
    createdDate: new Date()
  }
}
</script>

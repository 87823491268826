<template>
  <section data-name="system-config" class="fc w-100 h-100 fg-3 py-3 px-3 ovf-y-s hide-scroll-bar c-b-0">
    <table border>
      <thead>
        <tr>
          <th>Key</th>
          <th>Value</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="cfg in configs">
          <td>{{cfg.key}}</td>
          <td>{{cfg.value}}</td>
          <td>
            <button class="fn-btn" @click="api.systemConfig.unset(cfg.key)">
              <icon>fas fa-times@16:#d50404</icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div>
      <button @click="uploadFileS">Upload</button>
    </div>
    <div class="fr ai-c fg-1">
      <input class="f1" placeholder="Key" :value="key" @input="key = $event.target.value"/>
      <input class="f3" placeholder="Value" :value="value" @input="value = $event.target.value"/>
      <button @click="setConfig">Add</button>
    </div>
  </section>
</template>
<script setup>
import {onBeforeMount, onBeforeUnmount, onMounted, ref} from 'vue';
import api from '@/api';
import {openUploadFileDialog, uploadFile} from '@/components/UiLib/FileUpload/fileUploadLogic';
import notification from '@/components/UiLib/Api/notification';
import Icon from '@/components/UiLib/Icon';
import {copyToClipboard} from '@/utils';

const configs = ref([])
const key = ref('')
const value = ref('')
const setConfig = () => {
  api.systemConfig.set(key.value, value.value)
  key.value = ''
  value.value = ''
}

const uploadFileS = () => {
  openUploadFileDialog({multiple: false, mimeType: '*/*'}, async (files) => {
    try {
      const filePath = (await Promise.all(uploadFile(files)))[0]
      console.log('uploaded', filePath)
      copyToClipboard(filePath)
      notification.success('Upload completed. Url path has been copy to clipboard');
    } catch (e) {
      notification.err(e);
    }
  })
}
const loadConfigs = () => api.systemConfig.gets().then(rs => configs.value = rs)
onBeforeMount(() => loadConfigs())
onMounted(() => {
  window.socket.emit('watch', 'systemConfig');
  window.socket.on('systemConfig:set', cfg => loadConfigs())
  window.socket.on('systemConfig:unset', cfg => loadConfigs())
})
onBeforeUnmount(() => {
  window.socket.emit('un-watch', 'systemConfig');
  window.socket.off('systemConfig:set')
  window.socket.off('systemConfig:unset')
})
</script>

import axios from 'axios';
import {API_URL} from '@/constants';

export default {
  async login(u, p) {
    try {
      const {data} = await axios.post(`${API_URL}/user/sign-in`, { email: u, password: p })
      const {token, user} = data
      window.user = user
      if (token) {
        localStorage.setItem('access_token', token)
        axios.defaults.headers['Authorization'] = `Bearer ${token}`
      }
      return data
    } catch (e) {
      console.error(e)
    }
  },
  async auth(token) {
    try {
      const {data} = await axios.get(`${API_URL}/user/auth`, { headers: {'Authorization': `Bearer ${token}`}})
      window.user = data.user
      if (data.token) {
        localStorage.setItem('access_token', data.token)
        axios.defaults.headers['Authorization'] = `Bearer ${data.token}`
      }
      return data
    } catch (e) {
      console.error(e)
    }
  },
  async logout() {
    axios.post(`${API_URL}/user/sign-out`, {}).catch(console.error)
    localStorage.removeItem('access_token')
    delete axios.defaults.headers['Authorization']
    window.$router.push({path: '/sign-in'})
  }
}

<script>
import {ref, computed} from 'vue';
import Spacer from '@/components/UiLib/Spacer';
import Icon from '@/components/UiLib/Icon';
import AdminDashboard from '@/components/AdminDashboard';
import UserDashboard from '@/components/UserDashboard';
import ManageAccount from '@/components/ManageAccount';
import ManageKeyword from '@/components/ManageKeyword';
import ManageUserAgent from '@/components/ManageUserAgent';
import ManageProxy from '@/components/ManageProxy';
import ManageVideo from '@/components/ManageVideo';
import ManageVPS from '@/components/ManageVPS';
import ScriptEditor from '@/components/ScriptEditor';
import SystemLog from '@/components/SystemLog/SystemLog';
import AnalysisAccount from '@/components/AnalysisAccount';
import ScriptErrorFilter from '@/components/ScriptErrorFilter'
import SystemConfig from '@/components/SystemConfig';
import ScriptError from '@/components/ScriptError';
import Announcement from '@/components/Announcement';
import UserApi from '@/api/user';

export default {
  name: 'index',
  components: {Announcement, ScriptError, Spacer, Icon, AdminDashboard, UserDashboard, ManageAccount, ManageProxy, ManageVideo, ManageVPS, ManageUserAgent, AnalysisAccount, ScriptErrorFilter, SystemConfig},
  setup() {
    const sidebarContents = {
      'Announcement': { icon: 'fas fas fa-bell@20:#aaa', render: () => <Announcement/> },
      'Admin Dashboard': { icon: 'fas fa-play@20:#aaa', render: () => <AdminDashboard/>},
      'User Dashboard': { icon: 'fas fa-play@20:#aaa', render: () => <UserDashboard/> },
      'Video': { icon: 'fas fa-play@20:#aaa', render: () => <ManageVideo/> },
      'Account': { icon: 'fas fa-user@20:#aaa', render: () => <ManageAccount/> },
      'Account Analysis': { icon: 'fas fa-chart-bar@20:#aaa', render: () => <AnalysisAccount/> },
      'Keyword': { icon: 'fas fa-search@20:#aaa', render: () => <ManageKeyword/> },
      'Proxy': { icon: 'fas fa-mask@20:#aaa', render: () => <ManageProxy/> },
      'User Agent': { icon: 'fab fa-redhat@20:#aaa', render: () => <ManageUserAgent/> },
      'VPS': { icon: 'fas fa-server@20:#aaa', render: () => <ManageVPS/> },
      'Script': { icon: 'fas fa-file-code@20:#aaa', render: () => <ScriptEditor/> },
      'Script Error': { icon: 'fas fa-bug@20:#aaa', render: () => <ScriptError/> },
      'Script Error Filter': { icon: 'fas fa-bug@20:#aaa', render: () => <ScriptErrorFilter/> },
      'Log': { icon: 'fas fa-align-justify@20:#aaa', render: () => <SystemLog/> },
      'System Config': { icon: 'fas fa-cog@20:#aaa', render: () => <SystemConfig/> }
    }

    const sidebarItems = computed(() => {
      const output = []

      if (window.user.role === 'Admin') {
        output.push('Admin Dashboard', 'Script', 'Script Error', 'Script Error Filter', 'Log', 'System Config')
        selectedSidebarItem.value = 'Admin Dashboard'
      } else {
        output.push('Announcement', 'User Dashboard', 'VPS', 'Account', 'Proxy', 'Video', 'Keyword', 'User Agent', 'Account Analysis')
        selectedSidebarItem.value = 'User Dashboard'
      }

      return output
    })

    const vi = {
      'Announcement': 'Thông báo',
      'Admin Dashboard': 'Dashboard',
      'User Dashboard': 'Dashboard',
      'Video': 'Video',
      'Account': 'Tài khoản',
      'Keyword': 'Từ khoá',
      'Proxy': 'Proxy',
      'VPS': 'Worker',
      'Log': 'Log',
      'Account Analysis': 'Phân tích',
      'User Agent': 'User Agent',
      'Script': 'Script',
      'Script Error': 'Script Error',
      'Script Error Filter': 'Script Error Filter',
      'System Config': 'System Config'
    }

    const selectedSidebarItem = ref('Announcement');
    const renderSubPage = computed(() => {
      if (selectedSidebarItem.value)
        return sidebarContents[selectedSidebarItem.value].render
      return () => {}
    })

    const renderSidebarHeader = () => {
      return <div class="fr ai-c fg-1 px-1 py-2" style="height: 50px;">
        {window.user.username}
      </div>
    }
    const renderSideBarItems = () => {
      return sidebarItems.value.map(item => <div class="fr ai-c px-2 py-2 clickable" style={{
          cursor: 'pointer',
          margin: '4px 0px 4px 4px',
          borderRadius: '10px 0px 0px 10px',
          color: '#fff',
          fontSize: '14px',
          background: (selectedSidebarItem.value === item) ? '#303741' : 'transparent'
        }} onClick={() => selectedSidebarItem.value = item}>
          <icon class="item-icon">{sidebarContents[item].icon}</icon>
          <span class="item-text">{vi[item]}</span>
      </div>)
    }

    const renderSidebar = () => <div class="fc ovf-y-s hide-scroll-bar sidebar">
      {renderSidebarHeader()}
      {renderSideBarItems()}
      <spacer/>
      <div class="fr ai-c px-2 py-2 clickable" onClick={() => UserApi.logout()}>
        <icon class="item-icon">fas fa-sign-out-alt@20:#aaa</icon>
        <span class="item-text">Sign out</span>
      </div>
    </div>

    return () => <div class="fr h-100vh v-100vw" style="background-color: #121212">
      {renderSidebar()}
      <div class="ovf-h content">
        {renderSubPage.value()}
      </div>
    </div>
  }
}
</script>
<style scoped>
.sidebar {
  width: 56px;
  min-width: 56px;
  background-color: #363636;
  color: #fff
}

.content {
  width: calc(100% - 56px);
}

.item-icon {
  margin-right: 0;
}

.item-text {
  display: none;
}

@media screen and (min-width: 1024px) {
  .sidebar {
    width: 200px; min-width: 200px;
    background-color: #06070c;
    color: #fff
  }

  .item-icon {
    margin-right: 0.5em;
  }

  .item-text {
    display: initial;
  }

  .content {
    width: calc(100% - 200px);
  }
}
</style>
